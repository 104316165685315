function multiplyNumbers(numArray) {

    const res = numArray.reduce((a, b) => {
        return a * b;
    });

    return parseFloat(res.toFixed(2));
}

export { multiplyNumbers as default };
